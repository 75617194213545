import "core-js/modules/es.array.map";
import "core-js/modules/es.array.sort";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { configGradeList, expectNumList, gradeLog } from '../../../../api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      options: [{
        value: '0',
        label: '普通等级'
      }, {
        value: '1',
        label: '玉兔'
      }],
      searchType: '0',
      searchModel_1000: {
        "del": false,
        "id": "id_1000",
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "tag": "export",
          "title": "导出",
          "type": "primary"
        }],
        "searchData": [{
          "children": [{
            "field": [{
              "desc": "会员编号",
              "key": "number",
              "value": ""
            }, {
              "desc": "操作员",
              "key": "operator_man",
              "value": ""
            }, {
              "desc": "手机号",
              "key": "t_user.phone",
              "value": ""
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }, {
            "field": [{
              "desc": "操作者IP",
              "key": "operator_ip"
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }],
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "按条件",
          "type": 2,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "今天",
            "type": "button"
          }, {
            "field": [],
            "title": "昨天",
            "type": "button"
          }, {
            "field": [],
            "title": "最近7天",
            "type": "button"
          }, {
            "field": [],
            "title": "本月",
            "type": "button"
          }, {
            "field": [],
            "title": "本年",
            "type": "button"
          }, {
            "field": [],
            "title": "自定义",
            "type": "custom-datetime-range"
          }],
          "id": 1,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "定级日期",
          "type": 1,
          "remoteFunc": ["modelConditionFunc_1"]
        }, {
          "children": [{
            "field": [{
              "desc": "类型一",
              "key": "type",
              "value": "0"
            }, {
              "desc": "类型二",
              "key": "type",
              "value": "1"
            }],
            "multiple": false,
            "type": "select"
          }],
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "定级前级别",
          "type": 3,
          "remoteFunc": ["modelConditionFunc_2"]
        }, {
          "children": [{
            "field": [{
              "desc": "类型一",
              "key": "type",
              "value": "0"
            }, {
              "desc": "类型二",
              "key": "type",
              "value": "1"
            }],
            "multiple": false,
            "type": "select"
          }],
          "id": 3,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "定级后级别",
          "type": 3,
          "remoteFunc": ["modelConditionFunc_3"]
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [{
              "key": "type",
              "value": "0"
            }],
            "title": "手动",
            "type": "button"
          }, {
            "field": [{
              "key": "type",
              "value": "1"
            }],
            "title": "自动",
            "type": "button"
          }, {
            "field": [{
              "key": "type",
              "value": "3"
            }],
            "title": "报单",
            "total": 0,
            "type": "button"
          }],
          "id": 4,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "定级类型",
          "type": 0,
          "remoteFunc": []
        }],
        "type": "searchTemplate"
      },
      tableModel_1001: {
        "border": true,
        "del": false,
        "headerButton": [],
        "id": "id_1001",
        "isGroup": false,
        "isTree": false,
        "rowButton": [],
        "showPagination": true,
        "showSelection": false,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "ID",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "id"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员编号",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "number"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "手机号",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "t_user.phone"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "定级前级别",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "before_grade"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "定级后级别",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "after_grade"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "定级时间",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "add_time"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "定级类型",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "type"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "备注",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "200",
          "prop": "remark"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "操作员",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "operator_man"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "操作者IP",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "130",
          "prop": "operator_ip"
        }],
        "tableName": "t_grade_log",
        "tableRadioProps": {},
        "tableSelectProps": {
          "type": {
            "data": [{
              "key": 0,
              "value": "手动"
            }, {
              "key": 1,
              "value": "自动"
            }, {
              "key": 3,
              "value": "报单升级"
            }],
            "id": 0,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "before_grade": {
            "data": [{
              "key": null,
              "value": ""
            }],
            "id": 1,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": true,
            "remoteFunc": "tableFunc_select_before_grade"
          },
          "after_grade": {
            "data": [{
              "key": null,
              "value": ""
            }],
            "id": 2,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": true,
            "remoteFunc": "tableFunc_select_after_grade"
          }
        },
        "tableSwitchProps": {},
        "title": "定级日志",
        "type": "tableTemplate"
      },
      remoteFunc: {
        modelConditionFunc_1: function modelConditionFunc_1(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "add_time",
            value: []
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "add_time",
            value: []
          }]];
          resolve(tag, data);
        },
        modelConditionFunc_2: function modelConditionFunc_2(tag, relationData, resolve) {
          //筛选条件数据
          configGradeList({}).then(function _callee(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: 'before_grade',
                        value: item.sort,
                        desc: item.name
                      });
                    }

                    resolve(tag, [data]);

                  case 3:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        },
        modelConditionFunc_3: function modelConditionFunc_3(tag, relationData, resolve) {
          //筛选条件数据
          configGradeList({}).then(function _callee2(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee2$(_context2) {
              while (1) {
                switch (_context2.prev = _context2.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: 'after_grade',
                        value: item.sort,
                        desc: item.name
                      });
                    }

                    resolve(tag, [data]);

                  case 3:
                  case "end":
                    return _context2.stop();
                }
              }
            });
          });
        },
        modelConditionFunc_5: function modelConditionFunc_5(tag, relationData, resolve) {
          //筛选条件数据
          expectNumList({
            page: 1,
            limit: _this.$g.maxExpectNum
          }).then(function _callee3(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee3$(_context3) {
              while (1) {
                switch (_context3.prev = _context3.next) {
                  case 0:
                    data = [];

                    for (i = 0; i < res.length; i++) {
                      item = res[i];

                      if (i > 0) {
                        data.push({
                          key: 'expect_num',
                          value: item.expect_num,
                          desc: '第' + item.expect_num + '期(' + item.add_time.substring(0, 10) + ')'
                        });
                      }
                    }

                    resolve(tag, [data]);

                  case 3:
                  case "end":
                    return _context3.stop();
                }
              }
            });
          });
        },
        tableFunc_select_before_grade: function tableFunc_select_before_grade(tag, relationData, resolve) {
          //筛选项模型数组
          configGradeList({}).then(function _callee4(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee4$(_context4) {
              while (1) {
                switch (_context4.prev = _context4.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.tag,
                        value: item.name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context4.stop();
                }
              }
            });
          });
        },
        tableFunc_select_after_grade: function tableFunc_select_after_grade(tag, relationData, resolve) {
          //筛选项模型数组
          configGradeList({}).then(function _callee5(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee5$(_context5) {
              while (1) {
                switch (_context5.prev = _context5.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.tag,
                        value: item.name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context5.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var _this2 = this;

      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
      }

      params.where = where;

      if (this.searchType === "0") {
        params.where.sys = {
          op: "ne",
          value: 3
        };
      } else {
        params.where.sys = {
          op: "eq",
          value: 3
        };
      }

      gradeLog(params).then(function _callee6(res) {
        var i;
        return regeneratorRuntime.async(function _callee6$(_context6) {
          while (1) {
            switch (_context6.prev = _context6.next) {
              case 0:
                for (i in res.rows) {
                  if (_this2.searchType === "1") {
                    res.rows[i].befor_grade = "非玉兔";
                    res.rows[i].after_grade = "玉兔";
                  } else {
                    if (res.rows[i].grade == null) {
                      res.rows[i].grade = -1;
                    }
                  }
                }

                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 2:
              case "end":
                return _context6.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    }
  }
};