var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-button",
              {
                ref: "import",
                attrs: { type: "primary" },
                on: {
                  click: function($event) {
                    return _vm.clickImport()
                  }
                }
              },
              [_vm._v("导入补发表")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "success" },
                on: {
                  click: function($event) {
                    return _vm.clickDownTemplate()
                  }
                }
              },
              [_vm._v("下载模板")]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "userList" },
          _vm._l(_vm.userList, function(item) {
            return _c(
              "div",
              { staticClass: "userListItem" },
              [_c("el-tag", [_vm._v(_vm._s(item.number + " " + item.money))])],
              1
            )
          }),
          0
        ),
        _c("el-row", [
          _c("span", [
            _vm._v(
              _vm._s(
                "累计发放人数：" +
                  _vm.userList.length +
                  "人 累计金额：" +
                  _vm.bonusTotal()
              )
            )
          ])
        ]),
        _vm.userList.length > 0
          ? _c(
              "div",
              { staticStyle: { "margin-top": "20px" } },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.moneyType,
                          callback: function($$v) {
                            _vm.moneyType = $$v
                          },
                          expression: "moneyType"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: "money1" } }, [
                          _vm._v("消费账户")
                        ]),
                        _c("el-radio", { attrs: { label: "money2" } }, [
                          _vm._v("奖金账户")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { staticStyle: { "margin-top": "20px" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.clickSend }
                      },
                      [_vm._v("确定")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _c("input", {
      directives: [
        { name: "show", rawName: "v-show", value: false, expression: "false" }
      ],
      ref: "import",
      attrs: { type: "file", accept: ".xlsx" },
      on: {
        change: function($event) {
          return _vm.onExcelImport($event)
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }