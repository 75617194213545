import "core-js/modules/es.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import conditionCreator from '../conditionCreator';
export default {
  components: {
    conditionCreator: conditionCreator
  },
  props: {
    gradeList: {
      type: Array
    },
    levelList: {
      type: Array
    }
  },
  data: function data() {
    return {
      name: '',
      period: '',
      dialogVisible: false
    };
  },
  mounted: function mounted() {},
  methods: {
    onClose: function onClose() {
      this.finish();
    },
    show: function show(row, finish) {
      var _this = this;

      this.row = row;
      this.name = row.name;
      this.period = row.period;
      console.log('show', row);
      this.dialogVisible = true;
      this.finish = finish;
      this.$nextTick(function () {
        _this.$refs.creator.setCondData(_this.$tempModel.deepCopy(row.conditions.condData));
      });
    },
    clickSubmit: function clickSubmit() {
      console.log('111111');
      this.row.conditions.condData = this.$refs.creator.getCondData();
      console.log('222222');
      this.dialogVisible = false;
      this.finish();
    }
  }
};