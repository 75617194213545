import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import selectBatch from '@/views/pages/active/giftUser/selectBatch';
import { giftBatchList, giftUerHandle, giftUerImport, giftUserDel, giftUserList, productList } from '@/api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart,
    selectBatch: selectBatch
  },
  data: function data() {
    var _this = this;

    return {
      searchModel_1000: {
        "type": "searchTemplate",
        "id": "id_1000",
        "del": false,
        "searchData": [{
          "title": "按条件",
          "select": 0,
          "type": 2,
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "children": [{
            "type": "input-complex",
            "placeholder": "请输入",
            "field": [{
              "key": "number",
              "value": "",
              "desc": "会员编号"
            }],
            "operator": ["gte", "lte", "gt", "lt", "eq", "ne", "like", "notLike", "in", "between"]
          }],
          "remoteFunc": []
        }, {
          "title": "状态",
          "select": 0,
          "type": 0,
          "id": 1,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "children": [{
            "type": "button",
            "title": "全部",
            "field": []
          }, {
            "type": "button",
            "title": "未领取",
            "field": [{
              "key": "status",
              "value": "0"
            }]
          }, {
            "type": "button",
            "title": "已领取",
            "field": [{
              "key": "status",
              "value": "1"
            }]
          }],
          "remoteFunc": []
        }, {
          "title": "活动批次",
          "select": 0,
          "type": 3,
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "children": [{
            "type": "select",
            "multiple": false,
            "field": [{
              "key": "type",
              "value": "0",
              "desc": "类型一"
            }, {
              "key": "type",
              "value": "1",
              "desc": "类型二"
            }]
          }],
          "remoteFunc": ["modelConditionFunc_2"]
        }],
        "operateData": [{
          "title": "搜索",
          "type": "primary",
          "tag": "search",
          "icon": "el-icon-search"
        }, {
          "title": "导出",
          "type": "primary",
          "tag": "export",
          "icon": "el-icon-position"
        }, {
          "tag": "import",
          "type": "success",
          "title": "批量导入",
          "id": "",
          "icon": ""
        }],
        "isCollapse": false
      },
      tableModel_1001: {
        "border": true,
        "del": false,
        "headerButton": [],
        "id": "id_1001",
        "isGroup": false,
        "isTree": false,
        "rowButton": [{
          "id": "",
          "tag": "del",
          "title": "删除",
          "type": "danger"
        }],
        "showPagination": true,
        "showSelection": false,
        "showSummary": false,
        "stripe": true,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "领赠批次",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "batch_id"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员号码",
          "required": 1,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "number"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "状态",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": 80,
          "prop": "status"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "产品",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": 160,
          "prop": "ex_product.store_name"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "领取数量",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "package_num"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单号码",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": 160,
          "prop": "order_id"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "录入时间",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": 160,
          "prop": "add_time"
        }, {
          "label": "领取时间",
          "show": 1,
          "add": 0,
          "detail": 1,
          "width": "160",
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "exchange_time"
        }],
        "tableName": "t_gift_users",
        "tableRadioProps": {},
        "tableSelectProps": {
          "status": {
            "data": [{
              "key": 0,
              "value": "未领取"
            }, {
              "key": 1,
              "value": "已领取"
            }],
            "id": 0,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          }
        },
        "tableSwitchProps": {},
        "title": "领赠会员",
        "type": "tableTemplate"
      },
      remoteFunc: {
        modelConditionFunc_2: function modelConditionFunc_2(tag, relationData, resolve) {
          //筛选条件数据
          giftBatchList({
            page: 1,
            limit: 100000
          }).then(function _callee(res) {
            var data, i;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      data.push({
                        key: 'batch_id',
                        value: res.rows[i].batch_id,
                        desc: '(' + res.rows[i].batch_id + ')' + res.rows[i].batch_name
                      });
                    }

                    resolve(tag, [data]);

                  case 3:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        },
        tableFunc_select_package_id: function tableFunc_select_package_id(tag, relationData, resolve) {
          //筛选项模型数组
          productList({
            page: 1,
            limit: 1000,
            where: {
              is_main: 1
            }
          }).then(function _callee2(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee2$(_context2) {
              while (1) {
                switch (_context2.prev = _context2.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.id,
                        value: item.code + '-' + item.store_name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context2.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      giftUserList(params).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                // for(let i in res.rows){
                //   res.rows[i]['ex_product.store_name'] = '('+res.rows[i].package_id+')'+res.rows[i]['ex_product.store_name']
                // }
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
      }

      giftUerHandle(params).then(function _callee4(res) {
        return regeneratorRuntime.async(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context4.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      giftUserDel({
        batch_id: params[0].batch_id,
        number: params[0].number
      }).then(function _callee5(res) {
        return regeneratorRuntime.async(function _callee5$(_context5) {
          while (1) {
            switch (_context5.prev = _context5.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context5.stop();
            }
          }
        });
      });
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      var _this2 = this;

      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;

        if (results.length === 0) {
          return;
        }

        if (!results[0].hasOwnProperty('会员编号')) {
          this.$alert('请添加"会员编号"列', '提示', {
            confirmButtonText: '确定',
            callback: function callback(action) {}
          });
          return;
        }

        var tmp = [];

        for (var i in results) {
          tmp.push({
            number: results[i]['会员编号']
          });
        }

        this.$refs.batch.show(function (batch_id) {
          var params = {
            batch_id: batch_id,
            data: tmp
          };
          giftUerImport(params).then(function _callee6(res) {
            return regeneratorRuntime.async(function _callee6$(_context6) {
              while (1) {
                switch (_context6.prev = _context6.next) {
                  case 0:
                    _this2.$alert('导入成功', '提示', {
                      confirmButtonText: '确定',
                      callback: function callback(action) {
                        _this2.table.reloadData();
                      }
                    });

                  case 1:
                  case "end":
                    return _context6.stop();
                }
              }
            });
          });
        });
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};