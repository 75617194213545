import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { areaAdd, areaList, areaListByPid, areaDel } from '../../../../api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      region: [],
      options: [],
      tableModel_1000: {
        "border": true,
        "del": false,
        "headerButton": [{
          "id": "",
          "tag": "add",
          "title": "添加",
          "type": "primary"
        }],
        "id": "id_1000",
        "isGroup": false,
        "isTree": false,
        "rowButton": [{
          "id": "",
          "tag": "edit",
          "title": "编辑",
          "type": "primary"
        }, {
          "id": "",
          "tag": "del",
          "title": "删除",
          "type": "danger"
        }],
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "ID",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "id"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "区域名称",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "name"
        }],
        "tableName": "",
        "tableRadioProps": {},
        "tableSelectProps": {},
        "tableSwitchProps": {},
        "title": "区",
        "type": "tableTemplate"
      },
      remoteFunc: {}
    };
  },
  mounted: function mounted() {
    var _this2 = this;

    this.table = this.$refs.ref_1000;
    areaList({
      layer: 2
    }).then(function _callee(res) {
      var i, j;
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              for (i in res) {
                for (j in res[i].children) {
                  delete res[i].children[j].children;
                }
              }

              _this2.options = res;

            case 2:
            case "end":
              return _context.stop();
          }
        }
      });
    }); // let areaList = area.region
    // let tmp = []
    // for (let i in areaList){
    //   let item = areaList[i]
    //   let children = []
    //   for (let j in item.mallCityList){
    //     let cityItem = item.mallCityList[j]
    //     let subChildren = []
    //     // for (let k in cityItem.mallAreaList){
    //     //   let areaItem = cityItem.mallAreaList[k]
    //     //   // subChildren.push({value:areaItem.areaCode,label:areaItem.areaName})
    //     //   subChildren.push({value:areaItem.areaName,label:areaItem.areaName})
    //     // }
    //     // children.push({value:cityItem.cityCode,label:cityItem.cityName,children:subChildren})
    //     children.push({value:cityItem.cityName,label:cityItem.cityName})
    //   }
    //   // tmp.push({value:item.provinceCode,label:item.provinceName,children:children})
    //   tmp.push({value:item.provinceName,label:item.provinceName,children:children})
    // }
    // this.options = tmp
  },
  methods: {
    change: function change(value) {
      this.curProvince = value[0];
      this.curCity = value[1];
      this.table.reloadData();
    },
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      if (!this.curCity) {
        resolve({
          total: 0,
          data: []
        });
        return;
      }

      params = {
        pid: this.curCity
      };
      areaListByPid(params).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                resolve({
                  total: 0,
                  data: res
                });

              case 1:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
      } else {
        params.pid = this.curCity;
        params.layer = 2;
      }

      areaAdd(params).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      areaDel({
        id: ids
      }).then(function _callee4(res) {
        return regeneratorRuntime.async(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context4.stop();
            }
          }
        });
      });
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};