var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-card",
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c("span", [_vm._v("见点奖")]),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-left": "20px" },
                  attrs: { size: "mini", type: "success" },
                  on: { click: _vm.clickSave1 }
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.clickAddBonus1 }
                },
                [_vm._v("添加")]
              )
            ],
            1
          ),
          _c("el-tag", [_vm._v("如果填入金额<0,奖金按百分比计算")]),
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.bonus1 } },
            [
              _c("el-table-column", {
                attrs: { label: "序号", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", { staticStyle: { "margin-left": "10px" } }, [
                          _vm._v(_vm._s(scope.row.layer))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "金额", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          model: {
                            value: scope.row.bonus,
                            callback: function($$v) {
                              _vm.$set(scope.row, "bonus", $$v)
                            },
                            expression: "scope.row.bonus"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "是否是玉兔", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "是否是玉兔" },
                            model: {
                              value: scope.row.grade3,
                              callback: function($$v) {
                                _vm.$set(scope.row, "grade3", $$v)
                              },
                              expression: "scope.row.grade3"
                            }
                          },
                          [
                            _c("el-option", {
                              key: "0",
                              attrs: { label: "非玉兔", value: "0" }
                            }),
                            _c("el-option", {
                              key: "1",
                              attrs: { label: "玉兔", value: "1" }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.clickDeleteBonus1(
                                  scope.$index,
                                  scope.row
                                )
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c("span", [_vm._v("预备季")]),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-left": "20px" },
                  attrs: { size: "mini", type: "success" },
                  on: { click: _vm.clickSave2 }
                },
                [_vm._v("保存")]
              )
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.bonus3,
                "label-width": "120px",
                size: "small"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "推荐人数", prop: "direct" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    model: {
                      value: _vm.bonus3.paramUserNum,
                      callback: function($$v) {
                        _vm.$set(_vm.bonus3, "paramUserNum", _vm._n($$v))
                      },
                      expression: "bonus3.paramUserNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "份额分割数", prop: "number" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    model: {
                      value: _vm.bonus3.paramsShareNum,
                      callback: function($$v) {
                        _vm.$set(_vm.bonus3, "paramsShareNum", _vm._n($$v))
                      },
                      expression: "bonus3.paramsShareNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "份额百分比1", prop: "real_name" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        on: {
                          input: function(value) {
                            return _vm.handlePriceInput(
                              value,
                              _vm.bonus3,
                              "paramsShareRate1"
                            )
                          }
                        },
                        model: {
                          value: _vm.bonus3.paramsShareRate1,
                          callback: function($$v) {
                            _vm.$set(_vm.bonus3, "paramsShareRate1", $$v)
                          },
                          expression: "bonus3.paramsShareRate1"
                        }
                      }),
                      _c("el-tag", { staticStyle: { "margin-left": "20px" } }, [
                        _vm._v("<=分割数")
                      ])
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "份额百分比2", prop: "phone" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        on: {
                          input: function(value) {
                            return _vm.handlePriceInput(
                              value,
                              _vm.bonus3,
                              "paramsShareRate2"
                            )
                          }
                        },
                        model: {
                          value: _vm.bonus3.paramsShareRate2,
                          callback: function($$v) {
                            _vm.$set(_vm.bonus3, "paramsShareRate2", $$v)
                          },
                          expression: "bonus3.paramsShareRate2"
                        }
                      }),
                      _c("el-tag", { staticStyle: { "margin-left": "20px" } }, [
                        _vm._v(">分割数")
                      ])
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c("span", [_vm._v("预备季直推")]),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-left": "20px" },
                  attrs: { size: "mini", type: "success" },
                  on: { click: _vm.clickSave4 }
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.clickAddBonus4 }
                },
                [_vm._v("添加")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.bonus4 } },
            [
              _c("el-table-column", {
                attrs: { label: "层", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", { staticStyle: { "margin-left": "10px" } }, [
                          _vm._v(_vm._s(scope.row.layer))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "百分比", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          on: {
                            input: function(value) {
                              return _vm.handlePriceInput(
                                value,
                                scope.row,
                                "bonus"
                              )
                            }
                          },
                          model: {
                            value: scope.row.bonus,
                            callback: function($$v) {
                              _vm.$set(scope.row, "bonus", $$v)
                            },
                            expression: "scope.row.bonus"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.clickDeleteBonus4(
                                  scope.$index,
                                  scope.row
                                )
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c("span", [_vm._v("分红")]),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-left": "20px" },
                  attrs: { size: "mini", type: "success" },
                  on: { click: _vm.clickAddBonus1 }
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.clickAddBonus1 }
                },
                [_vm._v("添加")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.bonus2 } },
            [
              _c("el-table-column", {
                attrs: { label: "序号", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", { staticStyle: { "margin-left": "10px" } }, [
                          _vm._v(_vm._s(scope.row.layer))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "金额", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          model: {
                            value: scope.row.bonus,
                            callback: function($$v) {
                              _vm.$set(scope.row, "bonus", $$v)
                            },
                            expression: "scope.row.bonus"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.clickDeleteBonus1(
                                  scope.$index,
                                  scope.row
                                )
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("el-card", { staticStyle: { "margin-top": "20px" } }, [
        _c(
          "div",
          { attrs: { slot: "header" }, slot: "header" },
          [
            _c("span", [_vm._v("抽奖")]),
            _c(
              "el-button",
              {
                staticStyle: { float: "right", "margin-left": "20px" },
                attrs: { size: "mini", type: "success" },
                on: { click: _vm.clickGiftMake }
              },
              [_vm._v("奖品生成")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }