import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.join";
import "core-js/modules/es.array.last-index-of";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.parse-int";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempAdd from '@/components/rw/tempAdd/index';
import { systemSwitchList, systemSwitchAdd, systemSwitchSave, configGradeList } from '../../../../api/apis';
import upload from '@/plugin/upload';
export default {
  name: 'baseConfig',
  components: {
    tempAdd: tempAdd
  },
  data: function data() {
    return {
      gradeList: [],
      tableModel_1000: {
        "type": "tableTemplate",
        "id": "id_1000",
        "del": false,
        "tableModel": [{
          "label": "唯一标识",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "required": 1,
          "prop": "id"
        }, {
          "label": "名称",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "prop": "name"
        }, {
          "label": "描述",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "prop": "desc"
        }, {
          "label": "默认值",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "defaultValue": 0,
          "required": 0,
          "prop": "value"
        }, {
          "label": "数据类型",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "select",
          "desc": "",
          "groupId": "",
          "rules": [],
          "required": 1,
          "prop": "type"
        }, {
          "label": "显示端",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "select",
          "desc": "",
          "groupId": "",
          "rules": [],
          "required": 1,
          "prop": "platform"
        }, {
          "label": "组件",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "select",
          "desc": "",
          "groupId": "",
          "rules": [],
          "required": 1,
          "prop": "com"
        }, {
          "label": "显示分类",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "required": 1,
          "defaultValue": 1,
          "prop": "classify"
        }, {
          "label": "分组",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "required": 1,
          "prop": "group"
        }],
        "tableSwitchProps": {},
        "tableSelectProps": {
          "type": {
            "multiple": 0,
            "data": [{
              "key": "int",
              "value": "int"
            }, {
              "key": "string",
              "value": "string"
            }, {
              "key": "json",
              "value": "json"
            }],
            "remote": false,
            "id": 0,
            "relation": false,
            "relationProps": []
          },
          "platform": {
            "multiple": 1,
            "data": [{
              "key": "h5",
              "value": "h5"
            }, {
              "key": "op",
              "value": "op"
            }],
            "remote": false,
            "id": 1,
            "relation": false,
            "relationProps": []
          },
          "com": {
            "multiple": 0,
            "data": [{
              "key": "radio",
              "value": "radio"
            }, {
              "key": "checkbox",
              "value": "checkbox"
            }, {
              "key": "dateTimeRange",
              "value": "dateTimeRange"
            }, {
              "key": "input",
              "value": "input"
            }, {
              "key": "upload",
              "value": "upload"
            }],
            "remote": false,
            "id": 2,
            "relation": false,
            "relationProps": []
          }
        },
        "tableCascaderProps": {},
        "tableFileProps": {},
        "rowButton": [{
          "title": "详情",
          "tag": "detail",
          "id": ""
        }, {
          "title": "编辑",
          "type": "primary",
          "tag": "edit",
          "id": ""
        }, {
          "title": "删除",
          "type": "danger",
          "tag": "del",
          "id": ""
        }],
        "stripe": false,
        "border": true,
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "title": "",
        "tableName": "",
        "isGroup": false,
        "isTree": false,
        "headerButton": [{
          "title": "添加",
          "type": "primary",
          "tag": "add",
          "id": ""
        }],
        "tableAutocompleteProps": {},
        "tableRadioProps": {}
      },
      reloadTimer: '',
      data: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.reloadData();
    configGradeList({
      page: 1,
      limit: 10000
    }).then(function _callee(res) {
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _this.gradeList = res;

            case 1:
            case "end":
              return _context.stop();
          }
        }
      });
    });
  },
  methods: {
    reloadData: function reloadData() {
      var _this2 = this;

      var params = {
        where: {
          classify: 1
        }
      };
      systemSwitchList(params).then(function _callee2(res) {
        var kv, tmp, i, item, group, key, _item;

        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                kv = {};
                tmp = [];

                for (i in res) {
                  item = res[i];
                  group = item.group;

                  if (!kv.hasOwnProperty(group)) {
                    kv[group] = [];
                  }

                  if (item.type === 'int') {
                    item.value = parseInt(item.value);
                  }

                  kv[group].push(res[i]);
                }

                for (key in kv) {
                  _item = kv[key];
                  tmp.push({
                    key: key,
                    desc: _item
                  });
                }

                _this2.data = tmp;

              case 5:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    clickAdd: function clickAdd() {
      this.$refs['add'].show(false, null);
    },
    isSpecial: function isSpecial(group, key) {
      return group[key].com === 'sp1';
    },
    isSpecial2: function isSpecial2(group, key) {
      return group[key].com === 'sp2';
    },
    isRadio: function isRadio(group, key) {
      return group[key].com === 'radio';
    },
    isCheckBox: function isCheckBox(group, key) {
      return group[key].com === 'checkbox';
    },
    isDateTime: function isDateTime(group, key) {
      return group[key].com === 'dateTimeRange';
    },
    isInput: function isInput(group, key) {
      return group[key].com === 'input';
    },
    isUpload: function isUpload(group, key) {
      return group[key].com === 'upload';
    },
    _onReqAdd: function _onReqAdd(isEdit, params, row, resolveAdd) {
      var _this3 = this;

      console.log('_onReqAdd', params, row);
      new Promise(function (resolve) {
        var tmp = _this3.$tempModel.deepCopy(params);

        tmp.platform = tmp.platform.join(',');
        systemSwitchAdd(tmp).then(function _callee3(res) {
          return regeneratorRuntime.async(function _callee3$(_context3) {
            while (1) {
              switch (_context3.prev = _context3.next) {
                case 0:
                  _this3.$message({
                    message: '添加成功',
                    type: 'success'
                  });

                  _this3.reloadData();

                  resolveAdd();

                case 3:
                case "end":
                  return _context3.stop();
              }
            }
          });
        });
      }).then(function (error) {
        if (!error) {}

        resolveAdd(error);
      });
    },
    getFileList: function getFileList(group, tag) {
      var tmp = [];

      if (group[tag].value !== null && group[tag].value !== '') {
        var dic = {};
        dic.name = group[tag].value.substring(group[tag].value.lastIndexOf('/') + 1);
        dic.url = group[tag].value;
        tmp.push(dic);
      }

      return tmp;
    },
    clickSubmit: function clickSubmit() {
      var _this4 = this;

      var params = {};
      var tmp = [];

      for (var i in this.data) {
        var item = this.data[i];

        for (var j in item.desc) {
          tmp.push(item.desc[j]);
        }
      }

      params.data = tmp;
      systemSwitchSave(params).then(function _callee4(res) {
        return regeneratorRuntime.async(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                _this4.$message({
                  message: '保存成功',
                  type: 'success'
                });

              case 1:
              case "end":
                return _context4.stop();
            }
          }
        });
      });
    },
    onBeforeUpload: function onBeforeUpload(tag, file) {
      var size = file.size;

      if (size > 10 * 1024 * 1024) {
        this.$message.error('文件大于10MB');
        return false;
      }

      var extensionName = file.name.substr(file.name.lastIndexOf('.')); // 文件扩展名

      if (['.png', '.jpg', '.jpeg'].indexOf(extensionName) < 0) {
        this.$message.error('文件类型不正确');
        return false;
      }

      return true;
    },
    onUpload: function onUpload(tag, option) {
      console.log('ccc', option);
      upload.upload(option);
    },
    onSuccess: function onSuccess(group, tag, response, file, fileList) {
      // let pathList = []
      // pathList.push(response.url)
      group[tag].value = response.url;
      console.log('aaa', response.url);
    },
    onRemove: function onRemove(group, tag, file, fileList) {
      group[tag].value = '';
      console.log('onRemove'); // let pathList = []
      // for (let i in fileList) {
      //   pathList.push(fileList[i].url)
      // }
      // item.value = pathList
    },
    onError: function onError(tag, err, file, fileList) {
      this.$message.error(err.msg);
    }
  }
};