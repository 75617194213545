import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.parse-int";
import "core-js/modules/es.string.search";
import "core-js/modules/web.dom-collections.for-each";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { configBonusList, configGradeList, configLevelList, expectNumList, recommendHistoryList, recommendList } from '../../../../api/apis';
import { mapState, mapGetters, mapActions } from 'vuex';
import store from "@/store/index";
import setting from '../../../../setting';
export default {
  name: 'relationRecomment',
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      isMore: 0,
      activeNames: 'search',
      drawer: false,
      isRealTime: false,
      cacheChecked: {},
      searchModel_1000: {
        "del": false,
        "id": "id_1000",
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "tag": "export",
          "title": "导出",
          "type": "primary"
        }],
        "searchData": [{
          "children": [{
            "field": [{
              "desc": "会员编号",
              "key": "number",
              "value": ""
            }, {
              "desc": "手机号",
              "key": "phone",
              "value": ""
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }],
          "id": 1,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "会员编号",
          "type": 2,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [{
              "desc": "层级",
              "key": "layer",
              "value": ""
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }],
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "按层级",
          "type": 2,
          "remoteFunc": []
        }],
        "type": "searchTemplate"
      },
      tableModel_1001: {
        "border": true,
        "defaultExpandAll": true,
        "del": false,
        "headerButton": [],
        "id": "id_1001",
        "isGroup": false,
        "isTree": true,
        "rowButton": [{
          "icon": "",
          "id": "",
          "tag": "detail",
          "title": "详情",
          "type": null
        }],
        "rowKey": "number",
        "showPagination": false,
        "showSelection": false,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员编号",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "200",
          "prop": "number"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员状态",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "80",
          "prop": "status"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员名字",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "70",
          "prop": "real_name"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "推荐人",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "direct"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "当前等级",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "120",
          "prop": "grade"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "是否预备季",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "120",
          "prop": "is_ybj"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "手机号",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "phone"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "个人总业绩",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "110",
          "prop": "total_ppv"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "团队总业绩",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "110",
          "prop": "total_pov"
        }, {
          "label": "预备季个人业绩",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": "120",
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "total_ybj_ppv"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "预备季团队业绩",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "120",
          "prop": "total_ybj_pov"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "当月总业绩",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "90",
          "prop": "month_total_pov"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "直推人数",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "me_people"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "团队人数",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "pov_people"
        }],
        "tableName": "t_balance",
        "tableRadioProps": {},
        "tableSelectProps": {
          "grade": {
            "data": [{
              "key": null,
              "value": ""
            }],
            "id": 8,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": true,
            "remoteFunc": "tableFunc_select_grade"
          },
          "is_ybj": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 12,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "status": {
            "data": [{
              "key": 0,
              "value": "未激活"
            }, {
              "key": 1,
              "value": "正常"
            }, {
              "key": 5,
              "value": "冻结"
            }, {
              "key": 6,
              "value": "注销"
            }, {
              "key": 2,
              "value": "限制登录"
            }, {
              "key": 3,
              "value": "限制登录"
            }],
            "id": 13,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          }
        },
        "tableSwitchProps": {},
        "title": "推荐网",
        "treeProps": {
          "children": "children",
          "hasChildren": "hasChildren"
        },
        "type": "tableTemplate"
      },
      remoteFunc: {
        modelConditionFunc_5: function modelConditionFunc_5(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "pay_time",
            value: []
          }], [{
            key: "pay_time",
            value: []
          }]];
          resolve(tag, data);
        },
        tableFunc_select_grade: function tableFunc_select_grade(tag, relationData, resolve) {
          //筛选项模型数组
          configGradeList({}).then(function _callee(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.tag,
                        value: item.name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        },
        tableFunc_select_level: function tableFunc_select_level(tag, relationData, resolve) {
          //筛选项模型数组
          configLevelList({}).then(function _callee2(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee2$(_context2) {
              while (1) {
                switch (_context2.prev = _context2.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.tag,
                        value: item.name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context2.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    clickAllShow: function clickAllShow(isShow) {
      for (var i in this.tableModel_1001.tableModel) {
        var item = this.tableModel_1001.tableModel[i];
        item.show = isShow;
      }
    },
    listToTree: function listToTree(list, pid, parent) {
      var map = {};
      list.forEach(function (item) {
        if (!map[item.number]) {
          map[item.number] = item;
        }
      });
      list.forEach(function (item) {
        if (item.direct != parent) {
          map[item.direct].children ? map[item.direct].children.push(item) : map[item.direct].children = [item];
        }
      });
      return list.filter(function (item) {
        if (item.direct == parent) {
          return item;
        }
      });
    },
    reqTableData: function reqTableData(params, resolve) {
      var _this2 = this;

      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
      }

      where.asc = 0;
      params.where = where;

      if (this.isMore) {
        params.where.is_more = 1;
      } else {
        params.where.is_more = 0;
      }

      if (!params.where.hasOwnProperty('number') && !params.where.hasOwnProperty('phone') || !params.where.hasOwnProperty('layer')) {
        resolve({
          total: 0,
          data: []
        });
        return;
      }

      this.$g.fun.startLoading('数据计算中，请稍后...');
      recommendList(params).then(function _callee3(res) {
        var i, tmp;
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                for (i in res) {
                  if (res[i].grade == null) {
                    res[i].grade = -1;
                  }

                  if (res[i].pov_total_count != null) {
                    res[i].pov_total_count = parseInt(res[i].pov_total_count) + parseInt(res[i].me_total_count);
                    res[i].pov_total_num = parseInt(res[i].pov_total_num) + parseInt(res[i].me_total_num);
                    res[i].pov_total_pv = parseInt(res[i].pov_total_pv) + parseInt(res[i].me_total_pv);
                  }

                  if (res[i].total_ybj_ppv > 0) {
                    res[i].is_ybj = 1;
                  } else {
                    res[i].is_ybj = 0;
                  }
                }

                tmp = [];

                if (res.length > 0) {
                  tmp = _this2.listToTree(res, res[0].number, res[0].direct);
                }

                _this2.$g.fun.endLoading();

                resolve({
                  total: 0,
                  data: tmp
                });

              case 5:
              case "end":
                return _context3.stop();
            }
          }
        });
      }).catch(function () {
        _this2.$g.fun.endLoading();
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {
      //点击列表头部按钮回调
      this.drawer = true;
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    }
  }
};