import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { configBonusList, sendMoney3, userList } from '../../../../api/apis';
export default {
  data: function data() {
    return {
      userList: [],
      bonusTag: 0,
      moneyType: "money1"
    };
  },
  mounted: function mounted() {},
  methods: {
    clickImport: function clickImport() {
      this.$refs.import.value = null; //解决change事件在同一个文件下不触发的问题

      this.$refs.import.click();
    },
    clickDownTemplate: function clickDownTemplate() {
      var title = '账户补扣表模板';
      var columns = [{
        label: '会员编号',
        prop: 'number'
      }, {
        label: '金额',
        prop: 'money'
      }];
      this.$export.excel({
        columns: columns,
        data: [],
        size: 'mini',
        title: title
      }).then(function () {});
    },
    onExcelImport: function onExcelImport(event) {
      var _this = this;

      var file = event.target.files[0];
      this.$import.xlsx(file).then(function (_ref) {
        var header = _ref.header,
            results = _ref.results;

        if (results.length === 0) {
          return;
        }

        var item = results[0];

        if (!item.hasOwnProperty('会员编号') || !item.hasOwnProperty('金额')) {
          _this.$message({
            message: '表格字段错误，请先下载导入模板',
            type: 'error'
          });

          return;
        }

        var tmp = [];

        for (var i in results) {
          tmp.push({
            number: results[i]['会员编号'],
            money: results[i]['金额']
          });
        }

        _this.userList = tmp;
      }); // 阻止默认的上传

      return false;
    },
    bonusTotal: function bonusTotal() {
      var total = 0;

      for (var i in this.userList) {
        total += this.userList[i].money;
      }

      return total;
    },
    clickSend: function clickSend() {
      var _this2 = this;

      var dicNumber = {};

      for (var i in this.userList) {
        var number = this.userList[i].number;

        if (dicNumber.hasOwnProperty(number)) {
          this.$alert('数据中有重复的会员编号，请校验是否正确' + number, '提示', {
            confirmButtonText: '确定',
            callback: function callback(action) {}
          });
          return;
        }

        dicNumber[number] = 1;
      }

      this.$confirm('确定提交么', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function (_ref2) {
        var value = _ref2.value;

        _this2.$g.fun.startLoading('操作中......');

        sendMoney3({
          data: _this2.userList,
          money_type: _this2.moneyType
        }).then(function _callee(res) {
          return regeneratorRuntime.async(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  _this2.$g.fun.endLoading();

                  _this2.$alert('提交成功', '补扣金额', {
                    confirmButtonText: '确定',
                    callback: function callback(action) {}
                  });

                case 2:
                case "end":
                  return _context.stop();
              }
            }
          });
        }).catch(function (error) {
          _this2.$g.fun.endLoading();
        });
      });
    }
  }
};