import "core-js/modules/es.array.index-of";
import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
import setting from '@/setting';
import { systemInfo } from '@/api/apis';
import systemConfig from '../../../../../public/static/systemConfig';
export default {
  namespaced: true,
  state: {
    systemId: ''
  },
  actions: {
    /**
     * @description 设置用户数据
     * @param {Object} context
     * @param {*} info info
     */
    set: function set(_ref, sys) {
      var state = _ref.state,
          dispatch = _ref.dispatch;
      return new Promise(function _callee(resolve) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                setting.projectName = systemConfig.systemName;
                resolve();

              case 2:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },

    /**
     * @description 从数据库取用户数据
     * @param {Object} context
     */
    load: function load(_ref2) {
      var state = _ref2.state,
          dispatch = _ref2.dispatch;
      return new Promise(function _callee2(resolve) {
        var domain;
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                domain = document.domain;

                if (domain.indexOf("ry") >= 0) {
                  setting.projectName = "迪迪兔(仁义)后台管理";
                } else {
                  setting.projectName = "迪迪兔(智信)后台管理";
                }

                resolve();

              case 3:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    }
  }
};