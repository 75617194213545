import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { balanceReset, balanceStatus, configBalanceAdd, configBalanceList, manualSendBonus, manualUnSendBonus, pythonBalance, pythonNewBalance } from '../../../../api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      searchModel_1000: {
        "type": "searchTemplate",
        "id": "id_1000",
        "del": false,
        "searchData": [{
          "title": "按条件",
          "select": 0,
          "type": 2,
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "children": [{
            "type": "input-mul-complex",
            "placeholder": "请输入",
            "field": [{
              "key": "expect_num",
              "value": "",
              "desc": "期数"
            }]
          }],
          "remoteFunc": []
        }],
        "operateData": [{
          "title": "搜索",
          "type": "primary",
          "tag": "search",
          "icon": "el-icon-search"
        }, {
          "title": "导出",
          "type": "primary",
          "tag": "export",
          "icon": "el-icon-position"
        }]
      },
      tableModel_1001: {
        "border": true,
        "del": false,
        "headerButton": [{
          "tag": "default",
          "type": "success",
          "title": "重置结算状态",
          "id": "",
          "icon": ""
        }, {
          "tag": "default",
          "type": "danger",
          "title": "结算新的一期",
          "id": "",
          "icon": ""
        }],
        "id": "id_1001",
        "isGroup": false,
        "isTree": false,
        "rowButton": [{
          "id": "",
          "tag": "edit",
          "title": "编辑",
          "type": "primary"
        }, {
          "id": "",
          "tag": "default",
          "title": "重新结算",
          "type": "danger"
        }, {
          "id": "",
          "tag": "default",
          "title": "发放奖金",
          "type": "success"
        }, {
          "id": "",
          "tag": "default",
          "title": "撤销奖金",
          "type": "info"
        }],
        "showPagination": true,
        "showSelection": false,
        "stripe": true,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "ID",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": 0,
          "prop": "id"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "期数",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": 80,
          "prop": "expect_num"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "是否计算过",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "is_compute"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "计算次数",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "compute_count"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "奖金是否发放",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "is_send"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "发放日期",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "send_date"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "创建日期",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "add_time"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "结算开始日期",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "start_date"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "结算结束日期",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "end_date"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "备注",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "120",
          "prop": "remark"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "是否系统自动发放奖金",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "150",
          "prop": "is_auto_send"
        }],
        "tableName": "t_balance_config",
        "tableRadioProps": {},
        "tableSelectProps": {
          "is_compute": {
            "data": [{
              "key": 0,
              "value": "未计算过"
            }, {
              "key": 1,
              "value": "计算过"
            }],
            "id": 0,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "is_send": {
            "data": [{
              "key": 0,
              "value": "未发放"
            }, {
              "key": 1,
              "value": "已发放"
            }],
            "id": 1,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "is_auto_send": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 2,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          }
        },
        "tableSwitchProps": {},
        "title": "结算配置",
        "type": "tableTemplate"
      },
      remoteFunc: {}
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000; // this.balanceNotify()
  },
  methods: {
    balanceNotify: function balanceNotify() {
      var _this2 = this;

      setTimeout(function () {
        _this2.timerInt = setInterval(function () {
          balanceStatus({}).then(function _callee(res) {
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    console.log("balanceStatus", res);

                    if (res == 0) {
                      _this2.loading.close();

                      _this2.$notify({
                        title: '结算通知',
                        message: '结算完成',
                        type: 'success',
                        duration: 2000
                      });

                      clearInterval(_this2.timerInt);

                      _this2.table.reloadData();
                    }

                  case 2:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        }, 10 * 1000);
      }, 10 * 1000);
    },
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      configBalanceList(params).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 1:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
      }

      configBalanceAdd(params).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {
      var _this3 = this;

      //点击列表按钮回调
      if (btnIndex === 1) {
        this.$confirm('确定要手动结算当期么？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          pythonBalance({
            expect_num: row.expect_num
          }).then(function _callee4(res) {
            return regeneratorRuntime.async(function _callee4$(_context4) {
              while (1) {
                switch (_context4.prev = _context4.next) {
                  case 0:
                    _this3.isNotify = true;
                    _this3.loading = _this3.$loading({
                      lock: true,
                      text: '结算中，请稍等。。。',
                      spinner: 'el-icon-loading',
                      background: 'rgba(0, 0, 0, 0.7)'
                    });

                    _this3.balanceNotify();

                  case 3:
                  case "end":
                    return _context4.stop();
                }
              }
            });
          });
        }).catch(function () {});
      } else if (btnIndex === 2) {
        this.$confirm('确定要手动发放当期奖金么？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          manualSendBonus({
            expect_num: row.expect_num
          }).then(function _callee5(res) {
            return regeneratorRuntime.async(function _callee5$(_context5) {
              while (1) {
                switch (_context5.prev = _context5.next) {
                  case 0:
                    _this3.$message({
                      message: '发放成功',
                      type: 'success'
                    });

                    _this3.table.reloadData();

                  case 2:
                  case "end":
                    return _context5.stop();
                }
              }
            });
          });
        }).catch(function () {});
      } else if (btnIndex === 3) {
        this.$confirm('确定要撤销当期奖金么？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          manualUnSendBonus({
            expect_num: row.expect_num
          }).then(function _callee6(res) {
            return regeneratorRuntime.async(function _callee6$(_context6) {
              while (1) {
                switch (_context6.prev = _context6.next) {
                  case 0:
                    _this3.$message({
                      message: '撤销成功',
                      type: 'success'
                    });

                    _this3.table.reloadData();

                  case 2:
                  case "end":
                    return _context6.stop();
                }
              }
            });
          });
        }).catch(function () {});
      }
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {
      var _this4 = this;

      //点击列表头部按钮回调
      if (btnIndex === 0) {
        balanceReset({}).then(function _callee7(res) {
          return regeneratorRuntime.async(function _callee7$(_context7) {
            while (1) {
              switch (_context7.prev = _context7.next) {
                case 0:
                  _this4.$message({
                    message: '重置成功',
                    type: 'success'
                  });

                case 1:
                case "end":
                  return _context7.stop();
              }
            }
          });
        });
      } else if (btnIndex === 1) {
        this.$confirm('此操作会使整个系统创建新的一期，重新结算所有系统。确定要创建新的一期并结算么？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          pythonNewBalance({}).then(function _callee8(res) {
            return regeneratorRuntime.async(function _callee8$(_context8) {
              while (1) {
                switch (_context8.prev = _context8.next) {
                  case 0:
                    _this4.isNotify = true;
                    _this4.loading = _this4.$loading({
                      lock: true,
                      text: '结算中，请稍等。。。',
                      spinner: 'el-icon-loading',
                      background: 'rgba(0, 0, 0, 0.7)'
                    });

                    _this4.balanceNotify();

                  case 3:
                  case "end":
                    return _context8.stop();
                }
              }
            });
          });
        }).catch(function () {});
      }
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      if (btnIndex === 2) {
        if (row.is_send || !row.is_compute) {
          return false;
        }
      } else if (btnIndex === 3) {
        if (!row.is_send || !row.is_compute) {
          return false;
        }
      }

      return true;
    }
  }
};