import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { giftBatchList } from '@/api/apis';
export default {
  data: function data() {
    return {
      dialogVisible: false,
      batchList: [],
      form: {
        batch_id: ''
      },
      rules: {
        batch_id: [{
          require: true,
          message: '请选择活动批次',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    var _this = this;

    giftBatchList({
      page: 1,
      limit: 10000
    }).then(function _callee(res) {
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _this.batchList = res.rows;

            case 1:
            case "end":
              return _context.stop();
          }
        }
      });
    });
  },
  methods: {
    show: function show(finish) {
      this.dialogVisible = true;
      this.finish = finish;
    },
    clickSubmit: function clickSubmit() {
      var _this2 = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          _this2.dialogVisible = false;

          _this2.finish(_this2.form.batch_id);
        }
      });
    }
  }
};