import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.parse-int";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { janActiveList } from '@/api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  name: "janActive",
  data: function data() {
    var _this = this;

    return {
      searchModel_1000: {
        "type": "searchTemplate",
        "id": "id_1000",
        "del": false,
        "searchData": [{
          "title": "活动时间",
          "select": 0,
          "type": 1,
          "id": 1,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "children": [{
            "type": "button",
            "title": "全部",
            "field": []
          }, {
            "type": "button",
            "title": "今天",
            "field": []
          }, {
            "type": "button",
            "title": "昨天",
            "field": []
          }, {
            "type": "button",
            "title": "最近7天",
            "field": []
          }, {
            "type": "button",
            "title": "本月",
            "field": []
          }, {
            "type": "button",
            "title": "本年",
            "field": []
          }, {
            "type": "custom-datetime-range",
            "title": "自定义",
            "field": []
          }],
          "remoteFunc": ["modelConditionFunc_1"]
        }],
        "operateData": [{
          "title": "搜索",
          "type": "primary",
          "tag": "search",
          "icon": "el-icon-search"
        }, {
          "title": "导出",
          "type": "primary",
          "tag": "export",
          "icon": "el-icon-position"
        }],
        "isCollapse": false
      },
      tableModel_1001: {
        "type": "tableTemplate",
        "id": "id_1001",
        "del": false,
        "tableModel": [{
          "label": "序号",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "id"
        }, {
          "label": "会员编号",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "direct"
        }, {
          "label": "会员姓名",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "real_name"
        }, {
          "label": "会员电话",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "phone"
        }, {
          "label": "推荐人数",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "count"
        }],
        "tableSwitchProps": {},
        "tableSelectProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "rowButton": [],
        "stripe": false,
        "border": true,
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "title": "活动达标",
        "tableName": "",
        "isGroup": false,
        "isTree": false,
        "headerButton": [],
        "tableAutocompleteProps": {},
        "tableRadioProps": {}
      },
      remoteFunc: {
        modelConditionFunc_1: function modelConditionFunc_1(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "add_time",
            value: []
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "add_time",
            value: []
          }]];
          resolve(tag, data);
        },
        tableFunc_select_grade: function tableFunc_select_grade(tag, relationData, resolve) {
          //筛选项模型数组
          //[{ key: "新品", value: "0" }]
          var data = [];
          resolve(tag, data);
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.add_time = where.add_time;
      }

      janActiveList(params).then(function _callee(res) {
        var i, item;
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                for (i in res) {
                  item = res[i];
                  item.id = parseInt(i) + 1;
                }

                resolve({
                  total: 0,
                  data: res
                });

              case 2:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};